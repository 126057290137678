<div class="container">
  <div class="info">
    U krijgt steeds een bevestigingsmail na het maken van een online afspraak. Geen moment of locatie van
    voorkeur gevonden? Aarzel dan niet om alsnog
    <a href="tel:+32460230233">telefonisch</a> of via <a href="mailto:info@infans.be">e-mail</a> te
    contacteren, dan bekijken we dit even.
  </div>
</div>
<div class="container">
  <ul class="collapsible" (mInstance)="stepper = $event;" [mCollapsible]="{accordion: true, onCloseEnd: onStepCloseEnd.bind(this)}">
    <li class="active">
      <div class="collapsible-header" (click)="this.step = 0" [ngClass]="{'not-editable':this.step == 0}">
        <span class="bubble">
          <div *ngIf="!careFormGroup.valid; then stepBusy else stepDone"></div>
        </span>
        Afspraak maken
      </div>
      <div class="collapsible-body">
        <form [formGroup]="careFormGroup">
          <div class="input-field" [ngClass]="{invalid: !careFormGroup.controls.care.valid && careFormGroup.controls.care.touched}">
            <select mFormSelect #care id="care" formControlName="care" required>
              <optgroup *ngFor="let group of careTypeGroups" [label]="group.name">
                <option *ngFor="let careType of group.careTypes" [value]="careType.type">
                  <div>{{ careType.text }}</div>
                  <small class="row">
                    <span class="col s10">{{careType.office ? ' in consultatieruimte': ' aan huis'}}</span>
                    <span class="col s1" *ngIf="careType.duration">&nbsp;{{ careType.duration / 1000 / 60 / 60 }}u</span>
                  </small>
                </option>
              </optgroup>
            </select>
            <label [ngClass]="{active: careFormGroup.controls.care.valid}" for="care">Zorgaanvraag</label>
          </div>

          <div class="input-field" *ngIf="showVbd">
            <input type="text" id="vbd" formControlName="vbd" required [mDatepicker]="vbdInit()"
              [ngClass]="{invalid: !careFormGroup.controls.vbd.valid && touched}" />
            <label for="vbd">Vermoedelijke bevallingsdatum</label>
            <span class="helper-text" data-error="Kies een datum"></span>
          </div>

          <div class="input-field" *ngIf="showInfo">
            <select #info id="info" formControlName="info" mFormSelect>
              <option value="pril zwanger" >Pril zwanger</option>
              <option value="praktisch zwanger">Praktisch zwanger</option>
              <option value="arbeid en bevalling">Arbeid en bevalling</option>
              <option value="borstvoeding">(Borst)voeding</option>
              <option value="baby zorg en meer">Baby zorg en meer</option>
              <option value="transitie ouderschap">Transitie ouderschap</option>
            </select>
            <label for="info" [ngClass]="{active: careFormGroup.controls.info.valid}">Onderwerp sessie</label>
          </div>

          <div class="input-field">
            <textarea formControlName="question" id="question" type="text" class="materialize-textarea"></textarea>
            <label for="question">Informatie of vragen (optioneel)</label>
          </div>

          <p class="info" *ngIf="selectedCareType">
            <span *ngIf="isInOffice; else atHomeTemplate">Afspraak in consultatieruimte <a href="https://g.page/vroedvrouwella">Tijmstraat 23, 3990 Peer</a></span>
            <ng-template #atHomeTemplate>
              Afspraak aan huis. Bereken <u><a [routerLink]="['/']" fragment="simulation">hier uw reiskosten.</a></u>
            </ng-template>
            <small *ngIf="firstAppointment"><br/>Eénmalige opstartkost van € 20,00</small>
          </p>

          <div class="actions">
            <button class="right btn waves-effect waves-light" (click)="touched = true; initAppointmentService(); next(careFormGroup)">Volgende</button>
          </div>
        </form>
      </div>
    </li>
    <li>
      <div class="collapsible-header" (click)="this.step = 1; initAppointmentService();" [ngClass]="{'not-editable':careFormGroup.invalid || this.step == 1}">
        <span class="bubble">
          <div *ngIf="!datetimeFormGroup.touched else !datetimeFormGroup.valid ? stepBusy : stepDone">
            2
          </div>
        </span>
        Tijdstip
      </div>
      <div class="collapsible-body">
        <form [formGroup]="datetimeFormGroup">
          <div class="input-field">
            <select #midwife id="midwife" formControlName="midwife" mFormSelect>
              <option selected value="">Geen voorkeur</option>
              <option value="fJNdZ13AYiT2txQTWQA4KA1B0TK2">Ella</option>
              <option value="AMOiDd92zZeyYpMrn3G3FS5MjKj1">Mieke</option>
            </select>
            <label for="midwife" class="active">Vroedvrouw</label>
          </div>
          <div class="input-field" *ngIf="appointmentServiceReady; else spinnerTemplate">
            <input type="text" id="date" formControlName="date" required (mInstance)="mDatepicker = $event"
              [mDatepicker]="datepickerInit()" [ngClass]="{
                invalid:
                  !datetimeFormGroup.controls.date.valid &&
                  datetimeFormGroup.controls.date.touched
              }" />
            <label for="date">Datum</label>
          </div>
          <ng-template #spinnerTemplate> Loading </ng-template>
          <div *ngIf="availableHours.length > 0" class="input-field" [ngClass]="{invalid: !datetimeFormGroup.controls.time.valid && datetimeFormGroup.controls.time.touched}">
            <select #time id="time" formControlName="time" mFormSelect>
              <option *ngFor="let hours of availableHours" [value]="hours">
                {{ hours }}
              </option>
            </select>
            <label for="time" [ngClass]="{active: datetimeFormGroup.controls.time.valid}">Kies een uur</label>
            <div class="warning" *ngIf="!!selectedCareType && !isInOffice">
              Wij doen ons uiterste best om het gekozen uur te respecteren. Houdt er rekening mee
              dat de vroedvrouw iets vroeger of later kan zijn door omgestandigheden. Wanneer het
              niet lukt om tijdig langs te komen, belt de vroedvrouw naar jou.
            </div>
          </div>
          <div class="actions">
            <button class="btn-flat waves-effect waves-light" (click)="previous()">Terug</button>
            <button (click)="next(datetimeFormGroup)" class="right btn waves-effect waves-light">Volgende</button>
          </div>
        </form>
      </div>
    </li>
    <li>
      <div class="collapsible-header" (click)="this.step = 2" [ngClass]="{'not-editable':datetimeFormGroup.invalid || this.step == 2}">
        <span class="bubble">
          <!-- TODO fix dataFormGroup having touched status -->
          <div *ngIf="!dataFormGroup.touched || this.step < 2 else !dataFormGroup.valid ? stepBusy : stepDone">
            3
          </div>
        </span>
        Uw gegevens
      </div>
      <div class="collapsible-body">
        <form [formGroup]="dataFormGroup">
          <div *ngIf="!currentUser">
            Type jouw gegeven of <a (click)="loginModal()">meld je aan</a>
          </div>

          <div class="input-field">
            <input autocomplete formControlName="name" id="name" type="text" [ngClass]="{
                invalid:
                  !dataFormGroup.controls.name.valid && dataFormGroup.controls.name.touched
              }" />
            <label for="name">Voornaam</label>
            <span class="helper-text" data-error="Uw voornaam ontbreekt"></span>
          </div>
          <div class="input-field">
            <input autocomplete formControlName="surname" id="surname" type="text" [ngClass]="{
                invalid:
                  !dataFormGroup.controls.surname.valid &&
                  dataFormGroup.controls.surname.touched
              }" />
            <label for="surname">Achternaam</label>
            <span class="helper-text" data-error="Uw achternaam ontbreekt"></span>
          </div>
          <div class="input-field">
            <input autocomplete formControlName="phonenumber" id="phonenumber" type="tel" [ngClass]="{
                invalid:
                  !dataFormGroup.controls.phonenumber.valid &&
                  dataFormGroup.controls.phonenumber.touched
              }" />
            <label for="phonenumber">Telefoonnummer</label>
            <span class="helper-text" data-error="Uw telefoonnummer ontbreekt"></span>
          </div>
          <div class="input-field">
            <input autocomplete formControlName="email" id="email" type="email" [ngClass]="{
                invalid:
                  !dataFormGroup.controls.email.valid &&
                  dataFormGroup.controls.email.touched
              }" />
            <label for="email">E-mail</label>
            <span class="helper-text" data-error="Gelieve een geldig e-mailadres in te vullen"></span>
          </div>
          <div class="input-field">
            <input autocomplete formControlName="address" id="address" type="text" [ngClass]="{
                invalid:
                  !dataFormGroup.controls.address.valid &&
                  dataFormGroup.controls.address.touched
              }" />
            <label for="address">Volledig adres</label>
            <span class="helper-text"
              data-error="Gelieve uw volledig adres in te voeren, vb: Tijmstraat 23b, 3990 Peer"></span>
          </div>
          <div *ngIf="dataFormGroup.controls.newpassword" class="input-field">
            <input autocomplete formControlName="newpassword" id="newpassword" type="password" [ngClass]="{
                invalid:
                  !dataFormGroup.controls.newpassword.valid &&
                  dataFormGroup.controls.newpassword.touched
              }" />
            <label for="newpassword">Kies een wachtwoord</label>
            <span class="helper-text" data-error="Kies een wachtwoord"></span>
          </div>
          <label class="input-field">
            <input formControlName="agreeTermsAndConditions" type="checkbox" required />
            <span>Ik ga akkoord met het
              <a target="_blank" [routerLink]="['/privacy']" href="/privacy">privacy- en cookiesbeleid</a>. Infans
              mag mijn persoonsgegevens gebruiken voor de behandeling van deze afspraak en daarop
              volgende zorgverstrekkingen.</span>
          </label>
          <div class="actions">
            <button class="btn-flat waves-effect waves-light" (click)="previous()">Terug</button>
            <button (click)="validate(dataFormGroup)" class="right btn waves-effect waves-light">Bevestig</button>
          </div>
        </form>
      </div>
    </li>
    <li>
      <div class="collapsible-header not-editable" (click)="this.step = 3">
        <span class="bubble">
          <div *ngIf="this.step != 3 else stepDone">
            4
          </div>
        </span>
        Voltooid
      </div>
      <div class="collapsible-body">
        <p>Bedankt! Je ontvangt zodra een bevestingsmail op
          {{ dataFormGroup.controls.email.value }}. Toch niets ontvangen? Misschien is de email
          bij je spam berichten beland.</p>
        <div class="action">
          <button class="btn waves-effect waves-light" (click)="nextAppointment()">
            <svg class="icon small redo">
              <use xlink:href="assets/sprites.svg#redo"></use>
            </svg>
            Nog een afspraak</button>
        </div>
      </div>
    </li>
  </ul>

  <ng-template #stepBusy>
    <svg class="icon small">
      <use xlink:href="assets/sprites.svg#pencil-alt"></use>
    </svg>
  </ng-template>
  <ng-template #stepDone>
    <svg class="icon small">
      <use xlink:href="assets/sprites.svg#check"></use>
    </svg>
  </ng-template>
</div>