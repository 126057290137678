import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { Database, listVal, query, ref, orderByChild, startAt } from '@angular/fire/database';
import { dateRangesOverlap, getFormattedTime } from '../utils';
import { Schedule } from '../types/schedule-types';

@Injectable()
export class AppointmentService {
  private schedule: Schedule[];
  private ready = new ReplaySubject<boolean>(1);

  constructor(private database: Database) {}

  public init(): Observable<boolean> {
    if (!this.schedule) {
      listVal<Schedule>(query(ref(this.database, '/scheduled'), orderByChild('end'), startAt(new Date().getTime()))).subscribe(r => {
        this.schedule = r;
        this.ready.next(true);
      });
    }
    return this.ready.asObservable();
  }

  private filterPredicateMidwifeAvailable(q: Schedule, office: boolean, filterMidwife?: string) {
    return (filterMidwife ? q.uid === filterMidwife : true) && // filter midwife by uid when necessary
    (q.available === true) &&                                  // filter appointments where the midwife is available
    (office ? q.office : !q.office)                            // filter in office or outside of office
  }

  public disabledDay(d: Date, office: boolean, filterMidwife?: string, eventDuration?: number): boolean {
    // if there's an event in the schedule that day it means the day is not disabled
    return !this.schedule
      .filter(q => this.filterPredicateMidwifeAvailable(q, office, filterMidwife))
      .find(event => {
        const t = new Date(event.start);
        const eventStartDate = new Date(t.getFullYear(), t.getMonth(), t.getDate());
        const midwifeAvailable = d.getTime() === eventStartDate.getTime();
        if(eventDuration) {
          return midwifeAvailable && this.getAvailableHours(d, eventDuration, office, filterMidwife).length > 0
        }
        return midwifeAvailable;
      });
  }

  /**
   * Finds all available timeslots on a certain date for a given duration
   */
  public getAvailableHours(
    date: Date,
    eventDuration: number,
    office: boolean,
    filterMidwife?: string,
    availableTimeslotSpacing = 15,
  ): string[] {
    const availableHours = [];
    date.setHours(0, 0);
    let timeslot = new Date(date.getTime());
    while (date.getDay() === timeslot.getDay()) {
      if (
        this.isMoreThan24hoursFromNow(timeslot) &&
        this.isTimeslotAvailable(timeslot, eventDuration, office, filterMidwife)
      ) {
        availableHours.push(getFormattedTime(timeslot));
      }
      timeslot = new Date(timeslot.getTime() + availableTimeslotSpacing * 60 * 1000);
    }
    return availableHours;
  }

  private isMoreThan24hoursFromNow(d: Date): boolean {
    return d.getTime() - 24 * 60 * 60 * 1000 > new Date().getTime();
  }

  /** 
   * @returns first scheduled "available" or "office" event which doesnt have any appointment bookings yet
   */
  public isTimeslotAvailable(
    timeslot: Date,
    duration: number,
    office: boolean,
    filterMidwife?: string,
  ): Schedule {
    const availableArray = this.schedule
      .filter(event => this.filterPredicateMidwifeAvailable(event, office, filterMidwife))
      .filter(event => timeslot.getTime() >= event.start && timeslot.getTime() + duration <= event.end)

    const unavailableArray = this.schedule
      .filter(event => !event.available)
      .filter(event => dateRangesOverlap(event.start, event.end, timeslot.getTime(), timeslot.getTime() + duration));

    // if dates overlap the midwife is unavailable because of another appointment
    return availableArray.find(e => {
      // check for other appointments in the available hours
      const unavailable = unavailableArray
        .filter(event => event.uid === e.uid)
        .find(appointment => 
          // if dates overlap the midwife is unavailable because of another appointment
          dateRangesOverlap(timeslot.getTime(), timeslot.getTime() + duration, new Date(appointment.start).getTime(), new Date(appointment.end).getTime())
        )

      return !!!unavailable;
    });
  }
}
