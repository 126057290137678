<div class="carousel carousel-slider"
  [mCarousel]="{fullWidth: true, indicators: true, onCycleTo: resetAutoplay.bind(this)}" (mInstance)="mCarousel = $event;">
  <ul>
    <li index="0" class="carousel-item">
      <div class="caption left-align">
        <h1 class="title spaced">Infans</h1>
        <h2 class="subtitle grey-text text-lighten-3">Vroedvrouwenpraktijk</h2>
        <a class="btn gradient-bg" href="tel:+32460230233">0460 230 233</a>
      </div>
      <div class="background background-left">
        <img ngSrc="slider/Infans_studio-min.webp" sizes="100vw" fill priority alt="Vroedvrouwen Ella en Mieke">
      </div>
    </li>
    <li index="1" class="carousel-item">
      <div class="caption left-align">
        <h2 class="title">Vroedkundige zorgverlening</h2>
        <h3 class="subtitle grey-text text-lighten-3">Vanaf de zwangerschap tot het 1ste levensjaar</h3>
        <a [routerLink]="['/']" fragment="care" href="/#zorgaanbod" class="btn gradient-bg">Ontdek het hier</a>
      </div>
      <div class="background background-right">
        <img *ngIf="loadSlides[1]" ngSrc="slider/3-min.webp" alt="Luisteren op zwangere buik" sizes="100vw" fill />
      </div>
    </li>
    <li index="2" class="carousel-item">
      <div class="caption center-align">
        <h2 class="title">Afspraak nodig?</h2>
        <a [routerLink]="['/']" fragment="appointment" href="/#appointment" class="btn gradient-bg">Online boeken</a>
      </div>
      <div class="background">
        <img *ngIf="loadSlides[2]" ngSrc="slider/4-min.webp" alt="Hart op zwangere buik" sizes="100vw" fill />
      </div>
    </li>
    <li index="3" class="carousel-item">
      <div class="caption right-align">
        <h2 class="title">Draagconsult</h2>
        <a [routerLink]="['/workshop/draagconsult']" href="/workshop/draagconsult" class="btn gradient-bg">Passen en uitproberen</a>
      </div>
      <div class="background">
        <img *ngIf="loadSlides[3]" ngSrc="slider/draagconsult-min.webp" alt="Draagconsult" id="draagconsult-foto" sizes="100vw" fill />
      </div>
    </li>
    <li index="4" class="carousel-item">
      <div class="caption left-align">
        <h2 class="title">Kolfconsult</h2>
        <a [routerLink]="['/workshop/kolfconsult']" href="/workshop/kolfconsult" class="btn gradient-bg">Alles over kolven</a>
      </div>
      <div class="background">
        <img *ngIf="loadSlides[4]" ngSrc="slider/kolfconsult-min.webp" alt="kolfconsult" id="kolfconsult-foto" sizes="100vw" fill />
      </div>
    </li>
    <li index="5" class="carousel-item">
      <div class="caption right-align">
        <h2 class="title">Borstvoeding</h2>
        <a [routerLink]="['/workshop/zwangerschap']" href="/workshop/zwangerschap" class="btn gradient-bg">Interactieve workshop</a>
      </div>
      <div class="background">
        <img *ngIf="loadSlides[5]" ngSrc="slider/borstvoeding-min.webp" alt="Borstvoeding" sizes="100vw" fill />
      </div>
    </li>
    <li index="6" class="carousel-item">
      <div class="caption left-align">
        <h2 class="title">Babymassage</h2>
        <a [routerLink]="['/workshop/babymassage']" href="/workshop/babymassage" class="btn gradient-bg">Meer info</a>
      </div>
      <div class="background background-right">
        <img *ngIf="loadSlides[6]" ngSrc="slider/babymassage-min.webp" alt="Babymassage workshop" sizes="100vw" fill />
      </div>
    </li>
  </ul>

  <a class="nav-prev" (click)="prev()">
    <svg class="icon">
      <use xlink:href="assets/sprites.svg#chevron-left"></use>
    </svg>
  </a>
  <a class="nav-next" (click)="next()">
    <svg class="icon"><use xlink:href="assets/sprites.svg#chevron-right"></use></svg>
  </a>
</div>

<section id="about" class="section">
  <div class="center-align">
    <h2 class="gradient">Over ons</h2>
  </div>
  <infans-about></infans-about>
</section>

<section id="zorgaanbod" class="section grey lighten-5">
  <div class="center-align">
    <h2 class="gradient">Zorgaanbod</h2>
  </div>
  <infans-care></infans-care>
</section>

<section id="area" class="section">
  <div class="center-align">
    <h2 class="gradient">Regio</h2>
  </div>
  <infans-area></infans-area>
</section>

<section id="appointment" class="section grey lighten-5">
  <div class="center-align">
    <h2 class="gradient">Online een afspraak maken</h2>
  </div>
  <infans-appointment></infans-appointment>
</section>

<!--
<infans-chat *ngIf="!isMobile"></infans-chat>
-->
