
<div>
  <ul class="row tabs grey lighten-5">
      <li class="tab col s6">
        <a (click)="toggleRegistration(false)">Aanmelden</a>
        <div *ngIf="!isRegistration" class="activetab gradient-bg">&nbsp;</div>
      </li>
      <li class="tab col s6">
        <a (click)="toggleRegistration(true)">Registreren</a>
        <div *ngIf="isRegistration" class="activetab gradient-bg">&nbsp;</div>
      </li>
  </ul>

  <ng-container *ngTemplateOutlet="isRegistration? register : login"></ng-container>
  <ng-template #register>
    <div class="section center">
      <h4 class="gradient">Registreren</h4>
      <small class="d-block">Wanneer u zich registreert zal er een cookie aangemaakt worden.</small>

      <button (click)="doGoogleLogin()" class="btn">
        <img class="providerLogo" height="18" width="18" ngSrc="brands/Google__G__Logo.svg" alt="Google"/>
        Ga verder met Google
      </button>
      <button (click)="doFacebookLogin()" class="btn">
        <img class="providerLogo" height="18" width="18" ngSrc="brands/F_icon.svg" alt="Facebook"/>
        Ga verder met Facebook
      </button>
      <button (click)="doMicrosoftLogin()" class="btn">
        <img class="providerLogo" height="18" width="18" ngSrc="brands/Microsoft_logo.svg" alt="Microsoft"/>
        Ga verder met Microsoft
      </button>
      <button (click)="doTwitterLogin()" class="btn">
        <img class="providerLogo" height="18" width="18" ngSrc="brands/Twitter_Bird.svg" alt="Twitter"/>
        Ga verder met Twitter
      </button>

      <h6 class="light"><span> of </span></h6>

      <form>
        <div class="input-field">
          <input required autocomplete="email" type="email" id="emailaddress" name="emailaddress" [(ngModel)]="emailaddress">
          <label for="emailaddress">E-mail</label>
        </div>
        <div class="input-field">
          <input required autocomplete="password" type="password" id="password" name="password" [(ngModel)]="password">
          <label for="password">Kies een wachtwoord</label>
        </div>
        <button (click)="doEmailPwLogin()" class="btn gradient-bg white-text">Registreren</button>
        <div class="right-align">
          <small>
            <a (click)="forgotPassword(emailaddress)">Wachtwoord vergeten</a>
          </small>
        </div>
      </form>
    </div>
  </ng-template>

  <ng-template #login>
    <div class="section center">
      <h4 class="gradient">Aanmelden</h4>
      <small class="d-block">Wanneer u aanmeld zal er een cookie aangemaakt worden.</small>

      <button (click)="doGoogleLogin()" class="btn">
        <img class="providerLogo" height="18" width="18" ngSrc="brands/Google__G__Logo.svg" alt="Google"/>
        Aanmelden met Google
      </button>
      <button (click)="doFacebookLogin()" class="btn">
        <img class="providerLogo" height="18" width="18" ngSrc="brands/F_icon.svg" alt="Facebook"/>
        Aanmelden met Facebook
      </button>
      <button (click)="doMicrosoftLogin()" class="btn">
        <img class="providerLogo" height="18" width="18" ngSrc="brands/Microsoft_logo.svg" alt="Microsoft"/>
        Aanmelden met Microsoft
      </button>
      <button (click)="doTwitterLogin()" class="btn">
        <img class="providerLogo" height="18" width="18" ngSrc="brands/Twitter_Bird.svg" alt="Twitter"/>
        Aanmelden met Twitter
      </button>

      <h6 class="light"><span> of </span></h6>

      <form>
        <div class="input-field">
          <input required autocomplete="email" type="email" id="emailaddress" name="emailaddress" [(ngModel)]="emailaddress">
          <label for="emailaddress">E-mail</label>
        </div>
        <div class="input-field">
          <input required autocomplete="password" type="password" id="password" name="password" [(ngModel)]="password">
          <label for="password">Wachtwoord</label>
        </div>
        <button (click)="doEmailPwLogin()" class="btn gradient-bg white-text">Aanmelden</button>
        <div class="right-align">
          <small>
            <a (click)="forgotPassword(emailaddress)">Wachtwoord vergeten</a>
          </small>
        </div>
      </form>
    </div>
  </ng-template>
</div>
