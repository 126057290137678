import { inject } from '@angular/core';
import { first, map, switchMap } from 'rxjs/operators';
import { LoginService } from './services/login.service';
import { AppointmentService } from './services/appointment.service';
import { Router } from '@angular/router';

export const authGuard = () => {
  return inject(LoginService).getCurrentUserWithModal().pipe(
    map(user => !!user)
  );
}

export const adminGuard = () => {
  const loginService = inject(LoginService);
  return authGuard().pipe(
    switchMap(() => loginService.currentUser.pipe(first())),
    switchMap(user => loginService.isAdmin(user)),
    map(isAdmin => isAdmin ? true : inject(Router).createUrlTree(['/']))
  );
}

export const appointmentsInitGuard = () => {
  return inject(AppointmentService).init().pipe(first())
}