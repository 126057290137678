<div class="container">

  <div class="row">
    <div class="col s12 m5">
      <div class="card">
        <div class="card-image">
          <img class="activator" loading="lazy" src="assets/2022_ella-min.webp" alt="vroedvrouw Ella">
        </div>
        <div class="card-content">
          <span class="card-title activator grey-text text-darken-4">
            Manuella Mertens
            <svg class="icon right">
              <use xlink:href="assets/sprites.svg#ellipsis-v"></use>
            </svg>
          </span>
          <p class="black-text">Zelfstandige vroedvrouw</p>
          <p>Vroedvrouw met <a mTooltip data-position="bottom"
              data-tooltip="* bepaalde medicatie mag een vroedvrouw voorschrijven en hoef je niet voor langs te gaan bij uw arts."
              href="https://www.riziv.fgov.be/nl/professionals/individuelezorgverleners/vroedvrouwen/Paginas/vroedvrouw-geneesmiddelen-voorschrijven-.aspx"
              target="_blank" rel="noopener">voorschrijfrecht</a></p>
          <p>Draagconsulent</p>
          <p mTooltip data-position="bottom"
            data-tooltip="* lactatiekunde is de kennis van de menselijke borstvoeding en de processen die daarmee gemoeid zijn zoals melkproductie en melkafgifte, de samenstelling van de moedermelk en het drinken aan de borst. De lactatiekundige richt zich op de begeleiding van moeder en kind tijdens de lactatie.">
            <a href="https://www.bvl-borstvoeding.be/nl/(aanstaande)_ouders/meer_weten_over_de_begeleiding_door_een_lactatiekundige_(ibclc)/bekwaamheid_van_de_lactatiekundige_(ibclc)/231"
              target="_blank" rel="noopener">IBCLC Lactatiekundige</a>
          </p>
        </div>
        <div class="card-reveal">
          <span class="card-title grey-text text-darken-4">
            Manuella Mertens
            <svg class="icon right">
              <use xlink:href="assets/sprites.svg#times"></use>
            </svg>
          </span>
          <p>Hoi! Mijn naam is Manuella, je mag het gerust afkorten naar Ella ;)</p>
          <p>
            In 1989 ben ik op de internationale dag van de verpleging geboren in Mechelen. Na in Mechelen te zijn
            opgegroeid, heb ik in Leuven gewoond, gestudeerd en gewerkt. Mijn jaren in Leuven mag je gerust dynamisch
            noemen. Zo werd ik er trotse bezitter van de bachelor vroedkunde en de master management en beleid in de
            gezondheidszorg. Tijdens mijn bachelor vroedkunde heb ik een stage in Ecuador gedaan. Hiervoor ben ik de
            zomer voordien een intensieve cursus Spaans in Guatemala gaan volgen. Er is geen ontkennen aan, vroedkunde
            en reizen zijn mijn grootste passies! Ook op het gebied van werkervaring in de verpleeg- en vroedkunde is
            Leuven een grote pijler geweest. En laatst genoemd maar zeker niet minder waardevol, is het ook de stad waar
            ik de man heb ontmoet waar ik mijn hart aan verloren ben.
          </p>
          <p>In 2015 hebben we de stap gezet om een huisje te kopen in het knusse stadje Peer, waar in 2018 tevens onze
            eerste prachtige zoon is geboren. In 2021 kregen we een tweede fantastische zoon.
            Mijn oudste zoon heeft borstvoeding gekregen tot hij er zelf genoeg van had op 16 maanden. Hoelang mijn
            jongste borstvoeding zal krijgen, is nog even afwachten.
            Bijscholen vind ik enorm belangrijk, de lijst van bijscholingen die ik reeds gevolgd heb is behoorlijk en er
            is altijd wel iets waarin ik mij aan het bijscholen ben. Zo heb ik onder andere naast mijn cursus
            lactatiekunde ook een onafhankelijke en verdiepte cursus over flesvoeding gevolgd. Mijn oudste zoon was een
            zogenaamd ‘huilbaby’tje’, voor hem heb ik onder meer koemelk, soja en ei vrij dieet gevolgd, ik heb me toen
            enorm in koemelkallergie verdiept en ook de masterclass hierin gevolgd. Ook starten met vaste voeding
            volgens de Rapley methode beheers ik en heb ik toegepast bij mijn eigen kindjes.
          </p>
          <p>
            Na jaren ervaring in loondienst heb ik de stap gezet naar zelfstandige vroedvrouw in 2016. In 2019 heb ik
            het geluk gehad om een team te kunnen vormen met mijn enorm lieve collega Mieke, samen hebben we de praktijk
            Infans opgestart. Infans is de beste wending in mijn carrière. Vroedvrouw zijn, ik doe het met hart en ziel.
          </p>
        </div>
      </div>
    </div>

    <div class="col s12 m5 offset-m2">
      <div class="card">
        <div class="card-image">
          <img loading="lazy" class="activator" src="assets/2022_mieke-min.webp" alt="vroedvrouw Mieke">
        </div>
        <div class="card-content">
          <span class="card-title activator grey-text text-darken-4">
            Mieke Smeers
            <svg class="icon right">
              <use xlink:href="assets/sprites.svg#ellipsis-v"></use>
            </svg>
          </span>
          <p class="black-text">Zelfstandige vroedvrouw</p>
          <p>Vroedvrouw met <a mTooltip data-position="bottom"
              data-tooltip="* bepaalde medicatie mag een vroedvrouw voorschrijven en hoef je niet voor langs te gaan bij uw arts."
              href="https://www.riziv.fgov.be/nl/professionals/individuelezorgverleners/vroedvrouwen/Paginas/vroedvrouw-geneesmiddelen-voorschrijven-.aspx"
              target="_blank" rel="noopener">voorschrijfrecht</a></p>
          <p>Draagconsulent</p>
          <p mTooltip data-position="bottom"
            data-tooltip="* lactatiekunde is de kennis van de menselijke borstvoeding en de processen die daarmee gemoeid zijn zoals melkproductie en melkafgifte, de samenstelling van de moedermelk en het drinken aan de borst. De lactatiekundige richt zich op de begeleiding van moeder en kind tijdens de lactatie.">
            <a href="https://www.bvl-borstvoeding.be/nl/(aanstaande)_ouders/meer_weten_over_de_begeleiding_door_een_lactatiekundige_(ibclc)/bekwaamheid_van_de_lactatiekundige_(ibclc)/231"
              target="_blank" rel="noopener">IBCLC Lactatiekundige</a>
          </p>
        </div>
        <div class="card-reveal">
          <span class="card-title grey-text text-darken-4">
            Mieke Smeers
            <svg class="icon right">
              <use xlink:href="assets/sprites.svg#times"></use>
            </svg>
          </span>
          <p>
            Hoi! Ik stel me graag even voor. Ik ben Mieke al meer dan een decennia gelukkig getrouwd
            met Frank en samen zijn we trotse ouder van twee opgroeiende dochters, Emily en Stefany.
            In 2016 heb ik besloten om terug achter de boeken te kruipen om mijn droom waar te
            maken, namelijk vroedvrouw worden. Tijdens de opleiding werd me duidelijk dat ik me meer
            thuis voel in een thuissituatie. Een persoonlijke begeleiding waar een vertrouwensband kan
            ontstaan is een meerwaarde in de beleving van het koppel. Hierdoor was de beslissing om te
            starten als zelfstandige vroedvrouw niet moeilijk. Dankzij een goede stageplaats ben ik in
            contact gekomen met een zeer gedreven vroedvrouw Ella en vormen we nu een geweldig
            team.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col s12">
      <p>Infans staat zowat synoniem aan 'Ella en Mieke'. Wij werken graag als team samen naar een persoonlijke begeleiding op maat van jullie
        behoeften. Dit vanaf de kinderwens tot (meer dan) een jaar na de geboorte. Een goede vertrouwensband is in deze bijzondere periode van jullie
        leven zeer belangrijk. Daarom zien we jullie graag zo vroeg mogelijk om deze band te kunnen opbouwen.</p>
      <p>
        Door als team samen te werken, staan wij garant voor een continue bereikbaarheid. Daarnaast staan we bekend voor onze toch wel brede maar
        ook zeer actueel wetenschappelijk gebaseerde kennis, dit is ook wat we zelf heel erg belangrijk vinden. We scholen ons dan ook continu bij en
        stemmen dit op elkaar af om een gelijkgestemde begeleiding te waarborgen.</p>
      <p>
        Wij zijn alvast benieuwd naar wie jullie zijn, we beginnen graag vroeg met een kennismakingsgesprek waarin we <a [routerLink]="['/afspraak']"
        [state]="{ care: 'FIRST_CONSULT_PRENATAL' }" href="/afspraak">kennismaken</a>, uitgebreid
        vertellen wat je van ons kan verwachten en jullie optimaal kunnen beginnen begeleiden in de meest bijzondere gebeurtenis van jullie leven.
        Een afspraak boeken kan steeds via onze online afsprakenkalender <a [routerLink]="['/afspraak']" href="/afspraak">hier</a>.
      </p>
      <p>Nog vragen? Contacteer ons vrijblijvend op <a href="tel:+32460230233">0460 230 233</a> of stuur ons een e-mail op <a href="mailto:info@infans.be">info&#64;infans.be</a></p>
    </div>
  </div>

  <div class="row">
    <figure class="center-align">
      <img mMaterialbox width="150" height="150" ngSrc="assets/goodpracticelogo.webp" data-caption="Kwaliteitsvolle zorg" alt="good practice logo">
      <figcaption><a href="https://www.vroedvrouwen.be/good-practice-logo">Kwaliteitsvolle zorg</a></figcaption>
    </figure>
  </div>

</div>