import { LoginService } from './../../services/login.service';
import { Component, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { toast, updateTextFields } from '@steinv/ngx-materialize';
import { User } from '@angular/fire/auth';
import { NgxDialogController, NgxDialogViewComponent } from '@steinv/ngx-dialog';

@Component({
  selector: 'infans-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements NgxDialogViewComponent<User, LoginInput>, AfterViewInit {

  isRegistration: boolean;
  emailaddress: string;
  password: string;

  constructor(
    public readonly ngxDialogController: NgxDialogController<User, LoginInput>,
    private loginService: LoginService
  ) {
    this.isRegistration = ngxDialogController.input.registration === true;
    ngxDialogController.backdropClick().subscribe(() => ngxDialogController.dismiss())
  }

  ngAfterViewInit(): void {
    updateTextFields();
  }

  public toggleRegistration(b: boolean) {
    this.isRegistration = b;
    updateTextFields();
  }

  public doEmailPwLogin() {
    if (!this.emailaddress || !this.password) {
      toast({ html: 'Gelieve je e-mail en wachtwoord in te vullen' });
    } else {
      this.handleLoginAttempt(this.isRegistration ?
        this.loginService.registerEmailPw(this.emailaddress, this.password) :
        this.loginService.doEmailPwLogin(this.emailaddress, this.password)
      );
    }
  }

  public doMicrosoftLogin() {
    this.handleLoginAttempt(this.loginService.doMicrosoftLogin());
  }

  public doFacebookLogin() {
    this.handleLoginAttempt(this.loginService.doFacebookLogin());
  }

  public doGoogleLogin() {
    this.handleLoginAttempt(this.loginService.doGoogleLogin());
  }

  public doGithubLogin() {
    this.handleLoginAttempt(this.loginService.doGithubLogin());
  }

  public doTwitterLogin() {
    this.handleLoginAttempt(this.loginService.doTwitterLogin());
  }

  public handleLoginAttempt(promise: Promise<User>) {
    promise
      .then((u) => this.ngxDialogController.confirm(u))
      .catch(e => toast({ html: e }));
  }

  public forgotPassword(email: string) {
    if (email) {
      this.loginService.forgotPassword(email).then(() => {
        toast({ html: 'Er is een e-mail verstuurd. Gelieve je inbox te controleren.' });
      });
    } else {
      toast({ html: 'Gelieve eerst uw e-mail adres in te voeren.' });
    }
  }
}

export interface LoginInput {
  registration: boolean
}