import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { User } from '@angular/fire/auth';
import { Database, objectVal, push, ref, update } from '@angular/fire/database';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { toast, updateTextFields } from '@steinv/ngx-materialize';
import { of, Subject } from 'rxjs';
import { first, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { addressValidator, combineDateAndTime } from 'src/app/utils';
import { AppointmentService } from '../../services/appointment.service';
import { CareType, CareTypeInterface, CareTypesMap, EventType } from '../../types/appointment-types';
import { environment } from './../../../environments/environment';
import { LoginService } from './../../services/login.service';

const busRegex = new RegExp(' bus [0-9]+', 'i');

@Component({
  selector: 'infans-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentComponent implements OnInit, OnDestroy {
  appointmentServiceReady = false;
  availableHours: Array<string> = []
  currentUser: User;
  stepper: any;
  step = 0;
  datetimeFormGroup = this.formBuilder.group({
    midwife: new FormControl(''),
    date: new FormControl('', Validators.required),
    time: new FormControl('', Validators.required),
  });

  careFormGroup = this.formBuilder.group<{
    care: FormControl<CareType>,
    vbd?: FormControl<string>,
    info?: FormControl<string>,
    question: FormControl<string>,
  }>({
    care: new FormControl<CareType>(undefined, Validators.required),
    question: new FormControl(''),
  });

  dataFormGroup = this.formBuilder.group<{
    name: FormControl<string>,
    surname: FormControl<string>,
    phonenumber: FormControl<string>,
    email: FormControl<string>,
    address: FormControl<string>,
    agreeTermsAndConditions: FormControl<boolean>,
    newpassword?: FormControl<string>,
  }>({
    name: new FormControl('', Validators.required),
    surname: new FormControl('', Validators.required),
    phonenumber: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    address: new FormControl('', [Validators.required, addressValidator()]),
    agreeTermsAndConditions: new FormControl(false, Validators.requiredTrue),
    newpassword: new FormControl('', Validators.required),
  });

  mDatepicker: any;

  touched = false;
  careTypesMap = CareTypesMap;
  careTypeGroups: Array<{ name: string, careTypes: Array<CareTypeInterface> }> = [
    {
      name: 'Kinderwens',
      careTypes: [
        CareTypesMap[CareType.PRECONCEPTIONAL],
      ]
    },
    {
      name: 'Zwanger',
      careTypes: [
        CareTypesMap[CareType.FIRST_CONSULT_PRENATAL],
        CareTypesMap[CareType.PRENATAL_CONSULT],
        CareTypesMap[CareType.INFO_SESSION],
      ]
    },
    {
      name: 'Bevallen',
      careTypes: [
        CareTypesMap[CareType.FIRST_CONSULT_POSTNATAL],
        CareTypesMap[CareType.FIRST_CONSULT_POSTNATAL_OFFICE],
        CareTypesMap[CareType.POSTNATAL_CONSULT],
        CareTypesMap[CareType.POSTNATAL_CONSULT_OFFICE],
        CareTypesMap[CareType.INFO_SESSION],
      ]
    },
    {
      name: 'Andere',
      careTypes: [
        CareTypesMap[CareType.MISCARRIAGE],
        CareTypesMap[CareType.MISCARRIAGE_OFFICE]
      ]
    }
  ];

  destroyed$ = new Subject<void>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private database: Database,
    private appointmentService: AppointmentService,
    private loginService: LoginService,
  ) { }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit() {
    this.careFormGroup.get('care').valueChanges.subscribe(val => {
      if (val === CareType.FIRST_CONSULT_PRENATAL) {
        this.careFormGroup.addControl('vbd', new FormControl('', Validators.required));
      } else if (this.careFormGroup.contains('vbd')) {
        this.careFormGroup.removeControl('vbd');
      }

      if (val === CareType.INFO_SESSION) {
        this.careFormGroup.addControl('info', new FormControl(history.state.infosession, Validators.required));
      } else if (this.careFormGroup.contains('info')) {
        this.careFormGroup.removeControl('info');
      }

      
      this.changeDetectorRef.markForCheck();
    });

    this.datetimeFormGroup.controls.midwife.valueChanges.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(() => this.resetDateTime());

    this.loginService.currentUser.pipe(
      takeUntil(this.destroyed$),
      tap(user => this.currentUser = user),
      mergeMap((user) =>
        !!user ? objectVal(ref(this.database, '/users/' + user.uid)).pipe(first()) : of(undefined)
      ),
    ).subscribe((res) => {
      if (!res && !this.dataFormGroup.contains('newpassword')) {
        this.dataFormGroup.addControl('newpassword', new FormControl('', Validators.required));
      } else if (res && this.dataFormGroup.contains('newpassword')) {
        // TODO this makes the form dirty & touched
        this.dataFormGroup.removeControl('newpassword');
        this.dataFormGroup.patchValue(res);
        updateTextFields();
      }
      this.changeDetectorRef.markForCheck();
    });

    this.careFormGroup.controls.care.setValue(history.state.care);
  }

  public onStepCloseEnd(el: HTMLLIElement) {
    this.stepper.open(this.step);
  }
  
  public initAppointmentService() {
    this.appointmentService.init().pipe(first()).subscribe(() => {
      this.appointmentServiceReady = true;
      this.changeDetectorRef.markForCheck();
    });
  }

  public next(form: FormGroup) {
    form.markAllAsTouched();
    if(form.valid) { 
      this.step = this.step + 1;
      this.stepper.open(this.step);
    }
    this.changeDetectorRef.markForCheck();
  }

  public previous() {
    this.step = this.step - 1;
    this.stepper.open(this.step);
  }

  validate(form: FormGroup) {
    if (!form.valid) {
      form.markAllAsTouched();
      if (form.contains('agreeTermsAndConditions') && !form.controls.agreeTermsAndConditions.valid) {
        toast({ html: 'Gelieve onze voorwaarden te accepteren.' });
      }
    } else if (form.contains('agreeTermsAndConditions')) {
      this.updateProfile().then(() => {
        this.makeReservation();
        this.next(form)
      })
    }
  }

  loginModal() {
    this.loginService.openLoginModal();
  }

  updateProfile(): Promise<void> {
    if (this.currentUser) {
      return update(ref(this.database, '/users/' + this.currentUser.uid), this.dataFormGroup.value);
    } else {
      return this.loginService
        .registerEmailPw(this.dataFormGroup.controls.email.value, this.dataFormGroup.controls.newpassword?.value)
        .then(authenticated => {
          const customerData = this.dataFormGroup.value;
          delete customerData.newpassword;
          update(ref(this.database, '/users/' + authenticated.uid), customerData);
        })
        .catch(error => {
          toast({ html: error });
          throw error;
        });
    }
  }

  async makeReservation() {
    const chosenCare: CareTypeInterface = CareTypesMap[this.selectedCareType];
    const fullCare = chosenCare.type === CareType.INFO_SESSION ? chosenCare.text + ': ' + this.careFormGroup.get('info').value : chosenCare.text;
    const eventTitle = this.dataFormGroup.get('name').value + ' ' + this.dataFormGroup.get('surname').value + ' ' + fullCare;
    const startTime = combineDateAndTime(this.mDatepicker.date, this.datetimeFormGroup.get('time').value);
    const endTime = new Date(startTime.getTime() + chosenCare.duration);
    const midwife = this.datetimeFormGroup.get('midwife').value || this.appointmentService.isTimeslotAvailable(startTime, chosenCare.duration, chosenCare.office).uid;
    const fromAddress = midwife === 'fJNdZ13AYiT2txQTWQA4KA1B0TK2' ? 'Tijmstraat 23, 3990 Peer' : 'Kruisdijk 21, 3990 Peer'; // bij admin data bijsteken?
    const toAddress = chosenCare.office ? 'Tijmstraat 23, 3990 Peer' : this.dataFormGroup.get('address').value; // TODO office locatie bepalen
    const eventType = chosenCare.office ? EventType.customer_office : EventType.customer;
    objectVal(ref(this.database, '/admin/' + midwife)).pipe(
      first(),
      map((adminData: any) => {
        const color = adminData.config.schedule[eventType].color;
        return {
          uid: midwife,
          customer: this.currentUser.uid,
          address: toAddress,
          name: this.dataFormGroup.get('name').value,
          surname: this.dataFormGroup.get('surname').value,
          email: this.dataFormGroup.get('email').value,
          phonenumber: this.dataFormGroup.get('phonenumber').value,
          care: chosenCare.text,
          question: this.careFormGroup.get('question').value,
          description: 'Afspraak met de vroedvrouw.',
          start: startTime.getTime(),
          end: endTime.getTime(),
          title: eventTitle,
          eventType: eventType,
          info: this.getExtraInfo(),
          created: Date.now(),
          borderColor: color,
          backgroundColor: color,
          to: fromAddress,
          office: chosenCare.office,
        };
      }),
    ).subscribe(
      (data) => push(ref(this.database, '/appointments'), data)
    );
  }

  getExtraInfo(): string {
    let extraInfo = '';
    if (this.careFormGroup.contains('info')) {
      extraInfo += this.careFormGroup.get('info').value;
    }
    if (this.careFormGroup.contains('vbd')) {
      extraInfo += ' vermoedelijke bevallingsdatum ' + this.careFormGroup.get('vbd').value;
    }
    return extraInfo;
  }

  getDisabledDays(date: Date): boolean {
    const midwife = this.datetimeFormGroup.get('midwife').value;
    const chosenCare: CareTypeInterface = CareTypesMap[this.careFormGroup.get('care').value];
    return this.appointmentService.disabledDay.bind(this.appointmentService)(date, chosenCare.office, midwife, chosenCare.duration);
  }

  clearAvailableHours(): void {
    this.availableHours = [];
    this.datetimeFormGroup.controls.time.reset();
    this.changeDetectorRef.markForCheck();
  }

  getAvailableHours(): void {
    if(this.mDatepicker.date) {
      const midwife = this.datetimeFormGroup.get('midwife').value;
      const chosenCare: CareTypeInterface = CareTypesMap[this.careFormGroup.get('care').value];
      this.availableHours = this.appointmentService.getAvailableHours.bind(this.appointmentService)(this.mDatepicker.date, chosenCare.duration, chosenCare.office, midwife);
      this.changeDetectorRef.markForCheck();
    }
  }

  resetDateTime(): void {
    this.availableHours = [];
    this.datetimeFormGroup.patchValue({ date: '', time: '' });
    if (this.mDatepicker) {
      this.mDatepicker.setDate(undefined);
    }
    updateTextFields();
  }

  nextAppointment(): void {
    const data = this.dataFormGroup.value;
    const care = this.careFormGroup.value;

    this.careFormGroup.removeControl('info');
    this.careFormGroup.reset();
    this.datetimeFormGroup.reset();
    this.dataFormGroup.reset();

    this.availableHours = [];
    this.step = 0;
    this.stepper.open(this.step);

    this.careFormGroup.patchValue(care);
    this.dataFormGroup.patchValue(data);
  }

  datepickerInit() {
    const currentDate = new Date();
    const json = {
      minDate: new Date(currentDate.getTime() + 24 * 60 * 60 * 1000),
      disableDayFn: this.getDisabledDays.bind(this),
      onClose: this.getAvailableHours.bind(this),
      onOpen: this.clearAvailableHours.bind(this),
      firstDay: 1,
      format: 'dd-mm-yyyy',
      i18n: environment.calendar.i18n,
    };
    return json;
  }

  vbdInit() {
    const currentDate = new Date();
    const json = {
      firstDay: 1,
      format: 'dd-mm-yyyy',
      i18n: environment.calendar.i18n,
      defaultDate: currentDate,
    };
    return json;
  }

  public get firstAppointment(): boolean {
    return this.selectedCareType === CareType.FIRST_CONSULT_PRENATAL ||
      this.selectedCareType === CareType.FIRST_CONSULT_POSTNATAL_OFFICE ||
      this.selectedCareType === CareType.FIRST_CONSULT_POSTNATAL;
  }

  public get isInOffice(): boolean {
    const chosenCare: CareTypeInterface = CareTypesMap[this.selectedCareType];
    return chosenCare.office;
  }

  public get selectedCareType(): CareType {
    return this.careFormGroup.controls.care.value;
  }

  public get showVbd(): boolean {
    return this.careFormGroup.contains('vbd');
  }

  public get showInfo(): boolean {
    return this.careFormGroup.contains('info');
  }
}
