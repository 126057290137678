import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'infans-travelcost-simulator',
  templateUrl: './travelcost-simulator.component.html',
  styleUrls: ['./travelcost-simulator.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TravelcostSimulatorComponent {
  showSimulation = new BehaviorSubject(false);
  showAlert = false;
  showError = false;
  buttonDisabled = false;
  
  smallestDistance = 0;
  paying = 0;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {

  }

  removeAlert() {
    this.showAlert = false;
    this.changeDetectorRef.markForCheck();
  }

  distanceCalculator(address: HTMLInputElement) {
    if (!!address.value) {
      this.buttonDisabled = true;
      this.showError = false;
      this.showSimulation.next(false);
      this.changeDetectorRef.markForCheck();

      const req: google.maps.DistanceMatrixRequest = {
        avoidFerries: true,
        avoidHighways: false,
        avoidTolls: true,
        destinations: [address.value],
        origins: [
          'Tijmstraat 23, 3990 Peer',
          'Kruisdijk 21, 3990 Peer',
        ],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.METRIC
      };

      new google.maps.DistanceMatrixService().getDistanceMatrix(req, resp => {

        const distances: number[] = [];
        resp.rows.forEach(row => {
          row.elements.forEach(element => {
            if (google.maps.DistanceMatrixElementStatus.OK === element.status) {
              distances.push(element.distance.value);
            }
          });
        });

        if (distances.length > 0) {
          this.smallestDistance = Math.round(distances.sort((n1, n2) => n2 - n1).pop() / 100) / 10;
          this.paying = this.smallestDistance >= 20 ? Math.floor((this.smallestDistance - 10)/5) * 5 : 0;
          this.showSimulation.next(true);
        } else {
          this.showError = true;
        }
        this.buttonDisabled = false;
        this.showAlert = true;
        this.changeDetectorRef.markForCheck();
      });
    }
  }
}
