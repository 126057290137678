export interface AppointmentTypeInterface {
  type: EventType;
  defaultTitle: string;
  hint: string;
  office: boolean;
  subtypes?: Array<string>;
}

export interface Appointment {
  key?: string,
  address: string;
  backgroundColor: string;
  borderColor: string;
  care: CareType;
  created: number;
  customer: string;
  description: string;
  distance?: number;
  email: string;
  end: number;
  eventType: EventType;
  info: string;
  name: string;
  phonenumber: string;
  question?: string;
  start: number;
  surname: string;
  title: string;
  to?: string;
  uid: string;
  office?: boolean;
}

export enum CareType {
  FIRST_CONSULT_PRENATAL = 'FIRST_CONSULT_PRENATAL',
  FIRST_CONSULT_POSTNATAL = 'FIRST_CONSULT_POSTNATAL',
  FIRST_CONSULT_POSTNATAL_OFFICE = 'FIRST_CONSULT_POSTNATAL_OFFICE',
  PRENATAL_CONSULT = 'PRENATAL_CONSULT',
  POSTNATAL_CONSULT = 'POSTNATAL_CONSULT',
  POSTNATAL_CONSULT_OFFICE = 'POSTNATAL_CONSULT_OFFICE',
  INFO_SESSION = 'INFO_SESSION',
  MISCARRIAGE = 'MISCARRIAGE',
  MISCARRIAGE_OFFICE = 'MISCARRIAGE_OFFICE',
  PRECONCEPTIONAL = 'PRECONCEPTIONAL',
}

export interface CareTypeInterface {
  type: CareType;
  text: string;
  duration: number;
  office: boolean;
}

export const CareTypesMap: Record<CareType, CareTypeInterface> = {
  [CareType.FIRST_CONSULT_PRENATAL]: {
    type: CareType.FIRST_CONSULT_PRENATAL,
    text: 'Eerste afspraak, kennismaking tijdens zwangerschap',
    duration: 3600000,
    office: true,
  },
  [CareType.FIRST_CONSULT_POSTNATAL]: {
    type: CareType.FIRST_CONSULT_POSTNATAL,
    text: 'Eerste afspraak, kennismaking na de bevalling',
    duration: 3600000,
    office: false,
  },
  [CareType.FIRST_CONSULT_POSTNATAL_OFFICE]: {
    type: CareType.FIRST_CONSULT_POSTNATAL_OFFICE,
    text: 'Eerste afspraak, kennismaking na de bevalling',
    duration: 3600000,
    office: true,
  },
  [CareType.PRENATAL_CONSULT]: {
    type: CareType.PRENATAL_CONSULT,
    text: 'Begeleiding zwangerschap',
    duration: 3600000,
    office: true,
  },
  [CareType.POSTNATAL_CONSULT]: {
    type: CareType.POSTNATAL_CONSULT,
    text: 'Begeleiding kraamtijd',
    duration: 3600000,
    office: false,
  },
  [CareType.POSTNATAL_CONSULT_OFFICE]: {
    type: CareType.POSTNATAL_CONSULT_OFFICE,
    text: 'Begeleiding kraamtijd',
    duration: 3600000,
    office: true,
  },
  [CareType.INFO_SESSION]: {
    type: CareType.INFO_SESSION,
    text: 'Individuele infosessie',
    duration: 7200000,
    office: true,
  },
  [CareType.MISCARRIAGE]: {
    type: CareType.MISCARRIAGE,
    text: 'Miskraam begeleiding',
    duration: 7200000,
    office: false,
  },
  [CareType.MISCARRIAGE_OFFICE]: {
    type: CareType.MISCARRIAGE,
    text: 'Miskraam begeleiding',
    duration: 7200000,
    office: true,
  },
  [CareType.PRECONCEPTIONAL]: {
    type: CareType.PRECONCEPTIONAL,
    text: 'Preconceptioneel consult',
    duration: 3600000,
    office: true,
  },
};

export enum EventType {
  customer = 'customer',                  // klant afspraak aan huis
  customer_office = 'customer_office',    // klant afspraak in kantoor
  busy = 'busy',                          // bezet (vroedvrouw)
  available = 'available',                // beschikbaar (vroedvrouw)
  available_office = 'available_office',  // 
  info = 'info',
  workshop = 'workshop',
  phone = 'phone',
}

export const appointmentTypesMap: Map<EventType, AppointmentTypeInterface> = new Map([
  [
    EventType.customer,
    {
      type: EventType.customer,
      defaultTitle: 'Klantafspraak - Aan huis',
      hint: 'Klantafspraak inplannen aan huis',
      office: false,
    },
  ],
  [
    EventType.customer_office,
    {
      type: EventType.customer_office,
      defaultTitle: 'Klantafspraak - Kantoor',
      hint: 'Klantafspraak inplannen in kantoor',
      office: true,
    },
  ],
  [
    EventType.busy,
    {
      type: EventType.busy,
      defaultTitle: 'Bezet',
      hint: 'Eventjes bezet door een privéafspraak? Tandards, kinderen afhalen,.. zet je voor een korte periode onbeschikbaar voor online afspraken.',
      office: false,
    },
  ],
  [
    EventType.available,
    {
      type: EventType.available,
      defaultTitle: 'Beschikbaar - Aan huis',
      hint: 'Beschikbaar voor afspraken van klanten aan huis?',
      office: false,
    },
  ],
  [
    EventType.available_office,
    {
      type: EventType.available_office,
      defaultTitle: 'Beschikbaar - Kantoor',
      hint: 'Beschikbaar voor afspraken in het kantoor?',
      office: true,
    },
  ],
  [
    EventType.info,
    {
      type: EventType.info,
      defaultTitle: 'Informatief',
      hint: 'Informatief agendapunt',
      office: false,
    },
  ],
  [
    EventType.phone,
    {
      type: EventType.phone,
      defaultTitle: 'Telefoon permanentie',
      hint: 'Telefoon permanentie',
      office: false,
    },
  ],
]);
