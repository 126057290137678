<div class="container">
  <div class="row">
    <div class="col s12 m6">
      <!-- TODO kantoor mieke toevoegen-->
      <p>
        Tijdens de zwangerschap heten wij jou welkom in onze consultatieruimte te <a
          href="https://g.page/vroedvrouwella">Tijmstraat 23, 3990 Peer</a>.
      </p>
      <p>Het grootste deel van de prenatale
        opvolging en workshops vinden ook hier plaats. Geraakt u niet of moeilijk tot aan onze consultatieruimte? Neem
        gerust alsnog contact op via <a href="tel:+32460230233">telefoon</a> of <a href="mailto:info@infans.be">e-mail</a>, wij bekijken samen of de afspraken aan huis kunnen worden
        ingepland.
      </p>
      <p>
        De vroedkundige zorgverlening na de bevalling is aan huis.
        U hoeft zich dan geen zorgen te maken over verplaatsingen en blijft in uw vertrouwde omgeving
        tenzij u zelf de consultatie wenst te laten doorgaan in onze consultatieruimte.
      </p>
    </div>

    <div class="col s12 m6">
      <google-map [zoom]="zoom" [options]="options" *ngIf="loadMap">
        <ng-container *ngFor="let elem of markers">
          <map-marker #marker="mapMarker" [position]="elem.position" [options]="elem.options" (mapClick)="openInfoWindow(marker, infoWindow)"></map-marker>
          <!--
          <map-marker-clusterer [imagePath]="'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'">
            <map-marker #marker="mapMarker" [position]="elem.position" (mapClick)="openInfoWindow(marker, infoWindow)"></map-marker>
          </map-marker-clusterer>
          -->
          <map-info-window #infoWindow="mapInfoWindow" class="center-align">
            <img *ngIf="elem.image" [src]="elem.image"/>
            <div>{{elem.description}}</div>
          </map-info-window>
        </ng-container>
      </google-map>
    </div>

    <infans-travelcost-simulator class="col s12" id="simulation">
      <h6 class="gradient">Bereken reiskosten naar uw adres</h6>
    </infans-travelcost-simulator>
  </div>
</div>