/// <reference types="@types/google.maps" />
import { Component, ChangeDetectionStrategy, AfterContentInit, ChangeDetectorRef } from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { LazyLoadScriptService } from 'src/app/services/lazy-load-script.service';

export interface Marker {
  position: google.maps.LatLngLiteral,
  options: google.maps.MarkerOptions,
  description: string,
  image?: string,
}

@Component({
  selector: 'infans-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AreaComponent implements AfterContentInit {
  loadMap = false;
  zoom = 11;
  options = { 
    center: {lat: 51.086232, lng: 5.4643879 },
    gestureHandling: 'cooperative',
    mapTypeControl: false,
    scrollwheel: false,
    streetViewControl: false
  };

  markers: Marker[] = [];

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly lazyLoadScript: LazyLoadScriptService,
  ) { }

  ngAfterContentInit(): void {
    this.lazyLoadScript
      .loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyAXqLjO71v_l92AzJBlv0faJLsjh1eaCXg')
      .subscribe(() => {
        this.loadMap = true;
        this.markers = [
          {
            position: { lat: 51.088134, lng: 5.466867 }, 
            options: {
              title: 'Vroedvrouw Ella', 
              /**
              icon: {
                url:'../assets/2020-ella-min.jpg', 
                scaledSize: new google.maps.Size(50, 50)
              }
              */
            },
            description: `Vroedvrouw Ella`,
          },
          { 
            position: {lat: 51.086232, lng: 5.4643879 },   
            options: {
              title: 'Vroedvrouw Mieke', 
              /**
              icon: {
                url:'../assets/2020-mieke-min.jpg', 
                scaledSize: new google.maps.Size(50, 50)
              }
               */
            },
            description: 'Vroedvrouw Mieke'
          }];
          this.changeDetectorRef.markForCheck();
      });
  }

  public openInfoWindow(marker: MapMarker, infoWindow: MapInfoWindow) {
    infoWindow.open(marker);
  }
}
