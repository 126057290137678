import { NgOptimizedImage, PRECONNECT_CHECK_BLOCKLIST, provideImgixLoader, registerLocaleData } from '@angular/common';
import localeNlBE from '@angular/common/locales/nl-BE';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { browserLocalPersistence, browserPopupRedirectResolver, initializeAuth, provideAuth } from '@angular/fire/auth';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { connectFunctionsEmulator, getFunctions, provideFunctions } from '@angular/fire/functions';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { NgxMaterialize } from '@steinv/ngx-materialize';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AboutComponent } from './components/about/about.component';
import { AppointmentComponent } from './components/appointment/appointment.component';
import { AreaComponent } from './components/area/area.component';
import { CareComponent } from './components/care/care.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { TravelcostSimulatorComponent } from './components/travelcost-simulator/travelcost-simulator.component';
import { GlobalErrorHandler } from './error-handler';
import { authGuard } from './guard';
import { AppointmentService } from './services/appointment.service';
import { NgxDialogModule } from '@steinv/ngx-dialog';
import { LazyLoadScriptService } from './services/lazy-load-script.service';

registerLocaleData(localeNlBE);

const imgCdn = 'https://infans.imgix.net';
const routes: Routes = [
  {
    path: 'u',
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
    canActivate: [authGuard],
    runGuardsAndResolvers: 'always',
  },
  { path: 'afspraak', pathMatch: 'full', component: AppointmentComponent },
  {
    path: 'webshop',
    loadChildren: () => import('./webshop/webshop.module').then(m => m.WebshopModule),
    title: 'Webshop | Infans vroedvrouwenpraktijk'
  },
  {
    path: 'workshop',
    loadChildren: () => import('./workshop/workshop.module').then(m => m.WorkshopModule),
  },
  {
    path: 'workshop/babymassage',
    loadChildren: () => import('./workshop/workshop.module').then(m => m.WorkshopModule),
  },
  {
    path: 'workshop/zwangerschap',
    loadChildren: () => import('./workshop/workshop.module').then(m => m.WorkshopModule),
  },
  {
    path: 'workshop/kolfconsult',
    loadChildren: () => import('./workshop/workshop.module').then(m => m.WorkshopModule),
  },
  {
    path: 'workshop/draagconsult',
    loadChildren: () => import('./workshop/workshop.module').then(m => m.WorkshopModule),
  },
  {
    path: 'workshop/inschrijven/:id',
    loadChildren: () => import('./workshop/workshop.module').then(m => m.WorkshopModule),
  },
  {
    path: 'privacy',
    loadChildren: () => import('./components/privacy/privacy.module').then(m => m.PrivacyModule),
  },
  {
    path: 'verkoopsvoorwaarden',
    loadChildren: () => import('./components/terms-of-sale/terms-of-sale.module').then(m => m.TermsOfSaleModule),
  },
  {
    path: 'verkoopvoorwaarden',
    loadChildren: () => import('./components/terms-of-sale/terms-of-sale.module').then(m => m.TermsOfSaleModule),
  },
  { path: '', pathMatch: 'full', component: HomeComponent, title: 'Infans - vroedvrouwenpraktijk' },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AppointmentComponent,
    AboutComponent,
    AreaComponent,
    CareComponent,
    LoginComponent,
    TravelcostSimulatorComponent
  ],
  imports: [
    NgOptimizedImage,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      var auth = initializeAuth(getApp(), {
        persistence: browserLocalPersistence,
        popupRedirectResolver: browserPopupRedirectResolver
      })
      auth.languageCode = 'nl'
      return auth
    }),
    provideDatabase(() => getDatabase()),
    provideFunctions(() => {
      const funcs = getFunctions();
      if (environment.emulator) {
        connectFunctionsEmulator(funcs, 'localhost', 5001);
      }
      funcs.region = 'europe-west1'
      return funcs;
    }),
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    GoogleMapsModule,
    NgxMaterialize,
    NgxDialogModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 64],
    }),
  ],
  providers: [
    AppointmentService,
    { provide: LOCALE_ID, useValue: 'nl-BE' },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {provide: PRECONNECT_CHECK_BLOCKLIST, useValue: imgCdn},
    provideImgixLoader(imgCdn),
    LazyLoadScriptService,
  ],
  bootstrap: [AppComponent],
  exports: [RouterModule]
})
export class AppModule { }
