import { Injectable } from '@angular/core';
import { Auth, AuthProvider, authState, createUserWithEmailAndPassword, FacebookAuthProvider, fetchSignInMethodsForEmail, getIdTokenResult, GithubAuthProvider, GoogleAuthProvider, linkWithCredential, OAuthProvider, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup, TwitterAuthProvider, User } from '@angular/fire/auth';
import { toast } from '@steinv/ngx-materialize';
import { first, map, Observable, of, ReplaySubject, switchMap } from 'rxjs';
import { LoginComponent } from '../components/login/login.component';
import { NgxDialogService } from '@steinv/ngx-dialog';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private supportedPopupSignInMethods = [];
  private currentUserSubject: ReplaySubject<User | undefined> = new ReplaySubject(1);
  public currentUser = this.currentUserSubject.asObservable();

  public constructor(private readonly auth: Auth, private readonly ngxDialogService: NgxDialogService) {
    this.supportedPopupSignInMethods[GoogleAuthProvider.PROVIDER_ID] = new GoogleAuthProvider();
    this.supportedPopupSignInMethods[FacebookAuthProvider.PROVIDER_ID] = new FacebookAuthProvider();
    this.supportedPopupSignInMethods[GithubAuthProvider.PROVIDER_ID] = new GithubAuthProvider();
    this.supportedPopupSignInMethods[TwitterAuthProvider.PROVIDER_ID] = new TwitterAuthProvider();
    this.supportedPopupSignInMethods[new OAuthProvider('microsoft.com').providerId] = new OAuthProvider('microsoft.com');
    
    authState(this.auth).subscribe(user => {
      if (user) {
        localStorage.setItem('user', JSON.stringify(user));
        this.currentUserSubject.next(user);
      } else {
        this.currentUserSubject.next(undefined);
      }
    });
  }

  public getCurrentUserWithModal(): Observable<User> {
    return this.currentUser.pipe(
      first(),
      switchMap(user =>
        !!user ? of(user) : 
        this.openLoginModal().afterClosed().pipe(
          map(({data}) => data)
        )
      )
    )
  }

  public isAdmin(user: User): Promise<boolean> {
    return getIdTokenResult(user).then(r => !!r.claims.admin);
  }

  public logout(): Promise<void> {
    return this.auth.signOut().then(() => {
      localStorage.removeItem('user');
    });
  }

  public forgotPassword(email: string): Promise<void> {
    return sendPasswordResetEmail(this.auth, email);
  }

  public openLoginModal() {
    return this.ngxDialogService.open(LoginComponent, { registration: false });
  }

  private handleError(error, resolve, reject) {
    if ('auth/invalid-email' === error.code) {
      reject('Controleer je e-mailadres');
    } else if ('auth/weak-password' === error.code) {
      reject('Kies een sterker wachtwoord');
    } else if ('auth/wrong-password' === error.code) {
      reject('Wachtwoord foutief.');
    } else if ('auth/too-many-requests' === error.code) {
      reject('Account is geblokkeerd. Doe een wachtwoord reset');
    } else if ('auth/popup-closed-by-user' === error.code || 'auth/cancelled-popup-request' === error.code) {
      reject('Aanmelden geannuleerd');
    } else if ('auth/account-exists-with-different-credential' === error.code) {
      fetchSignInMethodsForEmail(this.auth, error.email).then(providers => {
        const providerId = providers.find(p => this.supportedPopupSignInMethods[p]);
        if (!providerId) {
          toast({ html: error.message });
          return;
        }
        const provider = this.supportedPopupSignInMethods[providerId];
        provider.setCustomParameters({ login_hint: error.email });
        signInWithPopup(this.auth, provider)
          .then(credentials => {
            linkWithCredential(credentials.user, error.credential)
              .then((u) => resolve(u.user));
          })
          .catch(err => this.handleError(err, resolve, reject));
      });
    } else {
      // Handle Errors here.
      reject(error.message);
    }
  }

  private doLogin(provider: AuthProvider): Promise<User> {
    return new Promise((resolve, reject) => {
      return signInWithPopup(this.auth, provider)
        .then(e => resolve(e.user))
        .catch(e => {
          this.handleError(e, resolve, reject);
        });
    });
  }

  public doEmailPwLogin(emailaddress: string, password: string): Promise<User> {
    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(this.auth, emailaddress, password)
        .then(e => resolve(e.user))
        .catch(e => {
          if ('auth/user-not-found' === e.code) {
            this.registerEmailPw(emailaddress, password)
              .then(result => resolve(result))
              .catch(error => this.handleError(error, resolve, reject));
          } else {
            this.handleError(e, resolve, reject);
          }
        });
    });
  }

  public registerEmailPw(emailaddress: string, password: string): Promise<User> {
    return new Promise((resolve, reject) => {
      createUserWithEmailAndPassword(this.auth, emailaddress, password)
        .then(e => {
          sendEmailVerification(e.user);
          toast({ html: 'Er is een verificatie e-mail verstuurd. Gelieve je inbox te controleren.' });
          resolve(e.user);
        })
        .catch(e => {
          if ('auth/email-already-in-use' === e.code) {
            fetchSignInMethodsForEmail(this.auth, emailaddress)
              .then(providers => {
                if (providers.indexOf('password') >= 0) {
                  this.doEmailPwLogin(emailaddress, password)
                    .then(result => resolve(result))
                    .catch(loginErr => reject(loginErr));
                } else {
                  reject(`E-mailadres reeds gekend. Probeer aan te melden met ${providers}`);
                }
              })
              .catch((err) => this.handleError(err, resolve, reject));
          } else {
            this.handleError(e, resolve, reject);
          }
        });
    });
  }

  public doMicrosoftLogin(): Promise<User> {
    const provider = new OAuthProvider('microsoft.com');
    provider.addScope('profile');
    provider.addScope('email');
    return this.doLogin(provider);
  }

  public doFacebookLogin(): Promise<User> {
    const provider = new FacebookAuthProvider();
    provider.addScope('public_profile');
    provider.addScope('email');

    return this.doLogin(provider);
  }

  public doGoogleLogin(): Promise<User> {
    const provider = new GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');

    return this.doLogin(provider);
  }

  public doGithubLogin(): Promise<User> {
    const provider = new GithubAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');

    return this.doLogin(provider);
  }

  public doTwitterLogin(): Promise<User> {
    const provider = new TwitterAuthProvider();
    return this.doLogin(provider);
  }
}
