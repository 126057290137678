import { ValidatorFn, Validators } from "@angular/forms";

export function addressValidator(): ValidatorFn {
  return Validators.pattern('^[A-Za-z0-9\\s\'\\/-]+\\s+[0-9]+[0-9A-Za-z, \\/\\.]*[0-9]{4}[a-zA-Z -]*$');
}

export function formattedStringToDate(dateString: string): Date {
  const dateSplitted = dateString.split('-');
  return new Date(+dateSplitted[2], +dateSplitted[1]-1, +dateSplitted[0]);
}

export function getFormattedDate(date: Date) {
  const year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return day + '-' + month + '-' + year;
}

export function getFormattedTime(date: Date) {
  let hours = date.getHours().toString();
  hours = hours.length > 1 ? hours : '0' + hours;

  let minutes = date.getMinutes().toString();
  minutes = minutes.length > 1 ? minutes : '0' + minutes;

  return hours + ':' + minutes;
}

export function combineDateAndTime(day: Date, time: string): Date {
  const eventTime = time.split(':');
  const dt = new Date(day.getTime());
  dt.setHours(Number(eventTime[0]), Number(eventTime[1]));
  return dt;
}

export function dateRangesOverlap(start1: number, end1: number, start2: number, end2: number): boolean {
  return Math.max(start1, start2) < Math.min(end1, end2);
}