import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'infans-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit, AfterViewInit {

  mCarousel;
  carouselInterval;
  loadSlides = [true, false, false, false, false, false, false];

  constructor(
    private metaService: Meta,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.metaService.updateTag({name: 'description', content: 'Vroedvrouwenpraktijk Infans, een vroedvrouw voor een pre- en postnatale begeleiding van jou en je kindje aan huis. Actief in de gemeentes Peer, Houthalen, Helchteren, Oudsbergen (Meeuwen-Gruitrode, Opglabbeek), Hechtel-Eksel, Heusden, Zolder, Zonhoven, ...'}, 'name="description"');
  }

  ngAfterViewInit(): void {
    this.autoplay();
  }

  autoplay() {
    this.carouselInterval = setInterval(() => {
      this.next();
    }, 10000);
  }

  next() {
    if(this.mCarousel) {
      this.mCarousel.next();
    }
  }

  prev() {
    if(this.mCarousel) {
      this.mCarousel.prev();
    }
  }

  resetAutoplay(e?: HTMLElement) {
    if(!this.loadSlides[e.getAttribute('index')]) {
      this.loadSlides[e.getAttribute('index')] = true;
      this.changeDetectorRef.markForCheck();
    }
    clearInterval(this.carouselInterval);
    this.autoplay();
  }
}
