import { LoginService } from './services/login.service';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { startWith } from 'rxjs/operators';
import { NgxDialogService } from '@steinv/ngx-dialog';

// Analytics and the function definition is loaded from the remote script located at https://www.googletagmanager.com/gtag/js .
// declare let gtag:Function;

// fbq is the function used by Facebook Pixel and the function definition is loaded from https://connect.facebook.net/en_US/fbevents.js .
// declare let fbq: Function;

@Component({
  selector: 'infans-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  authenticated = this.loginService.currentUser.pipe(
    startWith(JSON.parse(localStorage.getItem('user')))
  )

  public currentYear = new Date().getFullYear();

  constructor(private loginService: LoginService, private router: Router, public dialog: NgxDialogService) {}


  loginModal() {
    this.dialog.open(LoginComponent, { registration: false });
  }

  registerModal() {
    this.dialog.open(LoginComponent, { registration: true });
  }

  logout() {
    this.loginService.logout().then(() => this.router.navigate(['/']));
  }
}
