import {Injectable, ErrorHandler, Injector, isDevMode} from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';
import {Router} from '@angular/router';

const chunkFailedMessage = /Loading chunk [\d]+ failed/;
const noBotsRegex = new RegExp('(googlebot/|bot|Chrome-Lighthouse|bingbot|Google Page Speed Insights|HeadlessChrome)', 'i');

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: Error) {
    console.log(error);
    if (this.shouldBeLogged(error)) {
      console.log('reporting error to backend');
      const message = error.message + '\n' + error.stack;
      const func = this.injector.get(Functions);
      const currentUrl = this.injector.get(Router).url;
      const callable = httpsCallable(func, 'log')
      callable({msg: message, url: currentUrl})
      .then(
        () => {
          if (chunkFailedMessage.test(error.message)) {
            window.location.reload();
          }
        }
      )
    }
  }

  shouldBeLogged(error: Error): boolean {
    return !isDevMode() && !noBotsRegex.test(navigator.userAgent) && !this.isCaughtAuthenticationError(error);
  }

  isCaughtAuthenticationError(error: any): boolean {
    return error && error.code && typeof error.code == 'string' && (error.code as string).startsWith('auth/') && !error.stack;
  }
}
