<header>
  <nav class="veil">
    <div class="nav-wrapper">
      <a [routerLink]="['/']" href="/">
        <img class="brand-logo" priority width=200 height=64 ngSrc="logo-infans-long.webp" alt="Infans vroedvrouwenpraktijk logo" />
      </a>
      <a data-target="mobile-sidenav" class="sidenav-trigger waves-effect waves-light">
        <svg class="icon">
          <use xlink:href="assets/sprites.svg#bars"></use>
        </svg>
      </a>
      <ul class="right hide-on-med-and-down">
        <li><a [routerLink]="['/']" fragment="about" href="/#about">Over ons</a></li>
        <li><a [routerLink]="['/']" fragment="zorgaanbod" href="/#zorgaanbod">Zorgaanbod</a></li>
        <li><a [routerLink]="['/']" fragment="area" href="/#area">Regio</a></li>
        <li><a [routerLink]="['/']" fragment="appointment" href="/#appointment">Afspraak</a></li>
        <li><a [routerLink]="['/webshop']" href="/webshop">Webshop</a></li>
        <li><a mDropdown class="dropdown-trigger" data-target="submenu" href="#">
            <svg class="icon">
              <use xlink:href="assets/sprites.svg#th"></use>
            </svg>
          </a></li>
        <ng-container *ngTemplateOutlet="(authenticated | async) ? authenticatedMenu: publicMenu"></ng-container>
      </ul>
      <ng-template #authenticatedMenu>
        <li><a class="waves-effect waves-light" [routerLink]="['/u/profile']" href="/u/profile">
            <svg class="icon">
              <use xlink:href="assets/sprites.svg#cog"></use>
            </svg>
          </a></li>
        <li><a class="waves-effect waves-light" (click)="logout()">
            <svg class="icon">
              <use xlink:href="assets/sprites.svg#power-off"></use>
            </svg>
          </a></li>
      </ng-template>
      <ng-template #publicMenu>
        <li><a (click)="loginModal()" class="waves-effect waves-light btn white-text gradient-bg inline-block">Aanmelden</a></li>
      </ng-template>
      <div #submenu id="submenu" class="dropdown-content workshops">
        <a class="green-text square" [routerLink]="['/workshop/babymassage']" href="/workshop/babymassage">
          <svg class="icon">
            <use xlink:href="assets/sprites.svg#baby"></use>
          </svg>
          Babymassage
        </a>
        <a class="purple-text square" [routerLink]="['/workshop/draagconsult']" href="/workshop/draagconsult">
          <svg class="icon">
            <use xlink:href="assets/sprites.svg#ribbon"></use>
          </svg>
          Draagconsult
        </a>
        <a class="blue-text square" [routerLink]="['/workshop/kolfconsult']" href="/workshop/kolfconsult">
          <svg class="icon">
            <use xlink:href="assets/sprites.svg#breastpump"></use>
          </svg>
          Kolfconsult
        </a>
        <a class="pink-text square" [routerLink]="['/workshop/lactatieconsult']" href="/workshop/lactatieconsult">
          <svg class="icon">
            <use xlink:href="assets/sprites.svg#breastfeeding"></use>
          </svg>
          Lactatieconsult IBCLC
        </a>
        <a class="orange-text text-accent-2 square" [routerLink]="['/workshop/zwangerschap']" href="/workshop/zwangerschap">
          <svg class="icon">
            <use xlink:href="assets/sprites.svg#pregnant-woman"></use>
          </svg>
          Zwangerschapsworkshops
        </a>
      </div>
    </div>

    <ul mSidenav class="sidenav" id="mobile-sidenav">
      <ng-container *ngTemplateOutlet="(authenticated | async )? authenticatedMobileMenu: publicMobileMenu"></ng-container>
      <li class="divider" tabindex="-1"></li>
      <li><a class="sidenav-close" [routerLink]="['/']" fragment="about" href="/#about">Over ons</a></li>
      <li><a class="sidenav-close" [routerLink]="['/']" fragment="zorgaanbod" href="/#zorgaanbod">Zorgaanbod</a></li>
      <li><a class="sidenav-close" [routerLink]="['/']" fragment="area" href="/#area">Regio</a></li>
      <li><a class="sidenav-close" [routerLink]="['/']" fragment="appointment" href="/#appointment">Afspraak</a></li>
      <li><a class="sidenav-close" [routerLink]="['/webshop']" href="/webshop">Webshop</a></li>
      <li class="divider" tabindex="-1"></li>
      <li><a href="#" class="subheader">Workshops en consultaties</a></li>
      <li><a class="sidenav-close" [routerLink]="['/workshop/babymassage']" href="/workshop/babymassage">Babymassage</a></li>
      <li><a class="sidenav-close" [routerLink]="['/workshop/draagconsult']" href="/workshop/draagconsult">Draagconsult</a></li>
      <li><a class="sidenav-close" [routerLink]="['/workshop/kolfconsult']" href="/workshop/kolfconsult">Kolfconsult</a></li>
      <li><a class="sidenav-close" [routerLink]="['/workshop/lactatieconsult']" href="/workshop/lactatieconsult">Lactatieconsult IBCLC</a></li>
      <li><a class="sidenav-close" [routerLink]="['/workshop/zwangerschap']" href="/workshop/zwangerschap">Zwangerschapsworkshops</a></li>
    </ul>

    <ng-template #authenticatedMobileMenu>
      <li>
        <div class="user-view">
          <img *ngIf="authenticated?.photoURL" class="circle" [src]="authenticated.photoURL" alt="pofile picture"
            loading="lazy">
          <span class="white-text name">{{authenticated.displayName}}</span>
          <span class="white-text email">{{authenticated.email}}</span>
        </div>
      </li>
      <li><a class="sidenav-close btn waves-effect waves-light" [routerLink]="['/u/profile']" href="/u/profile">Profiel</a></li>
      <li><a class="waves-effect waves-light" (click)="logout()">Afmelden</a></li>
    </ng-template>
    <ng-template #publicMobileMenu>
      <li><a (click)="loginModal()" class="waves-effect waves-light btn white-text gradient-bg">Aanmelden</a></li>
      <li><a (click)="registerModal()" class="waves-effect waves-light">Registreren</a></li>
    </ng-template>
  </nav>
</header>

<main>
  <router-outlet></router-outlet>
</main>

<footer class="page-footer veil">
  <div class="row">
    <div class="col m3 s12 offset-m1">
      <div class="row">
        <div class="col s7">
          <h5>Infans</h5>
          <em>Together for life</em>
        </div>
        <div class="col s4 pull-s1">
          <!-- IBCLC -->
          <a target="_blank" rel="noopener" href="https://www.bvl-borstvoeding.be/nl/(aanstaande)_ouders/meer_weten_over_de_begeleiding_door_een_lactatiekundige_(ibclc)/bekwaamheid_van_de_lactatiekundige_(ibclc)/231">
            <img  class="responsive-img" src="assets/ibclc.webp" alt="IBCLC Lactatiekundige certificate">
          </a>
        </div>
      </div>
    </div>
    <div class="col m3 s12">
      <h5>Webshop</h5>
      <ul>
        <li>
          <a [routerLink]="['/verkoopvoorwaarden']" href="/verkoopvoorwaarden">Verkoopvoorwaarden</a>
        </li>
        <li>
          BTW: BE0768912268
        </li>
        <li><a href="mailto:webshop@infans.be">
            <svg class="icon small">
              <use xlink:href="assets/sprites.svg#envelope"></use>
            </svg>
            webshop&#64;infans.be
          </a>
        </li>
        <li class="paymentbrands">
          <img class="icon" width="57" height="40" ngSrc="brands/bancontact.svg" alt="Bancontact" />
        </li>
      </ul>
      <!-- TrustBox widget - Review Collector 
      <div class="trustpilot-widget" data-locale="nl-BE" data-template-id="56278e9abfbbba0bdcd568bc"
        data-businessunit-id="6107910f971967001dd32945" data-style-height="52px" data-style-width="200px">
        <a href="https://nl-be.trustpilot.com/review/infans.be" target="_blank" rel="noopener">Trustpilot</a>
      </div>
       End TrustBox widget -->
    </div>
    <div class="col m3 s12">
      <h5>Contact</h5>
      <ul>
        <li><a href="mailto:info@infans.be">
            <svg class="icon small">
              <use xlink:href="assets/sprites.svg#envelope"></use>
            </svg>
            info&#64;infans.be
          </a></li>
        <li><a href="tel:+32460230233">
            <svg class="icon small">
              <use xlink:href="assets/sprites.svg#phone"></use>
            </svg>
            0460 230 233
          </a></li>
        <li class="contactbrands">
          <svg class="icon blue-grey-text">
            <use xlink:href="assets/sprites.svg#sms"></use>
          </svg>
          <svg class="icon green-text">
            <use xlink:href="assets/sprites.svg#whatsapp"></use>
          </svg>
          <svg class="icon facebook-messenger">
            <defs>
              <linearGradient id="myGradient" x1="50%" y1="0%" x2="50%" y2="100%">
                <stop stop-color="#00B2FF" offset="0%"></stop>
                <stop stop-color="#006AFF" offset="100%"></stop>
              </linearGradient>
            </defs>
            <use xlink:href="assets/sprites.svg#facebook-messenger"></use>
          </svg>
        </li>
      </ul>
    </div>
    <div class="col m2 s12">
      <h5>Volg ons</h5>
      <ul>
        <li><a href="https://www.facebook.com/Infansvroedvrouwenpraktijk/">
            <svg class="icon small">
              <use xlink:href="assets/sprites.svg#facebook-square"></use>
            </svg>
            Facebook</a></li>
        <li><a href="https://www.instagram.com/infans.vroedvrouwen/">
            <svg class="icon small">
              <use xlink:href="assets/sprites.svg#instagram"></use>
            </svg>
            Instagram</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="footer-copyright">
    <div class="container">
      © 2019 - {{currentYear}} Copyright Infans
      <a class="right" [routerLink]="['privacy']" href="/privacy">Privacy en cookiebeleid</a>
    </div>
  </div>
</footer>