<ng-content></ng-content>
<form>
    <div class="input-field">
        <input required type="text" #simulationAddress id="simulationAddress" name="simulationAddress" />
        <label for="simulationAddress">Uw volledig adres</label>
    </div>
    <button type="submit" [disabled]="buttonDisabled" (click)="distanceCalculator(simulationAddress)"
        class="btn waves-effect waves-light">
        Doe een simulatie
    </button>
</form>
<div class="alert" *ngIf="showAlert">
    <a class="close waves-effect waves-light btn-flat" (click)="removeAlert()">
        <svg class="icon">
            <use xlink:href="assets/sprites.svg#times"></use>
        </svg>
    </a>
    <div *ngIf="showSimulation | async" class="info">
        <ul>
            <ul>
                <li>Afstand tot {{ simulationAddress.value }}: {{ smallestDistance | number: '1.1-1' }}km</li>
                <li>Reiskostenvergoeding:  {{ paying  | currency: 'EUR':'symbol-narrow':'1.2-2' }}</li>
            </ul>
        </ul>
        <div *ngIf="price > 0">
            In sommige gevallen is er een tegemoetkoming van het ziekenfonds of andere verzekeraar
            mogelijk voor reiskosten:
            <ul class="browser-default">
                <li>Bij prenatale zittingen van een risicozwangerschap (doktersattest vereist).</li>
                <li>Sommige hospitalisatieverzekeringen komen hierin tegemoet. Dit kan u navragen bij uw verzekeraar.</li>
            </ul>
        </div>
    </div>
    <div *ngIf="showError" class="error">
        Simulatie naar adres <strong>{{ simulationAddress.value }}</strong> is niet gelukt.
        Probeer uw adres zo volledig mogelijk te noteren. <br />
        Lukt het nog niet? Contacteer ons vrijblijvend op het telefoonnummer
        <a href="tel:+32460230233">0460 230 233</a>.
    </div>
</div>