<div class="row">
  <div class="container" id="collapsible">
    <ul class="collapsible" [mCollapsible]="{accordion: false}">
      <li>
        <div class="collapsible-header">
          <svg class="icon">
            <use xlink:href="assets/sprites.svg#pregnant-woman"></use>
          </svg>
          Zwangerschap
        </div>
        <div class="collapsible-body">
          <div class="row">
            <div class="col m8">
              <ul class="collection transparent">
                <li class="collection-item transparent">
                  Vaststelling zwangerschap
                </li>
                <li class="collection-item transparent">
                  Openen van een persoonlijk dossier
                </li>
                <li class="collection-item transparent">
                  Planning van de vroedkundige raadplegingen in combinatie met de
                  raadplegingen bij de gynaecoloog
                </li>
                <li class="collection-item transparent">
                  De nodige controles, ondersteuning en informatie omtrent de zwangerschap
                </li>
                <li class="collection-item transparent">
                  Medische onderzoeken zoals luisteren naar harttonen van de baby, urinetest, suikertest, bloedafname,
                  ...
                </li>
                <li class="collection-item transparent">
                  Voorbereiding op bevalling en kraamtijd
                </li>
                <li class="collection-item transparent">
                  Opvolging van een risicozwangerschap op voorschrift
                </li>
              </ul>
            </div>
            <figure class="col m4">
              <img loading="lazy" mMaterialbox src="assets/doppler-long-min.webp" alt="luisteren naar harttonen" class="img-thumbnail" />
              <figcaption>Foto: Luisteren naar harttonen</figcaption>
            </figure>
          </div>
        </div>
      </li>
      <li>
        <div class="collapsible-header">
          <svg class="icon">
            <use xlink:href="assets/sprites.svg#baby"></use>
          </svg>
          Kraamtijd
        </div>
        <div class="collapsible-body">
          <div class="row">
            <ul class="collection">
              <li class="collection-item transparent">
                Bij het eerste bezoek wordt o.a. een persoonlijk dossier geopend, dit is idealiter reeds gebeurd tijdens
                de zwangerschap
              </li>
              <li class="collection-item transparent">
                Onderzoek van moeder (bijvoorbeeld bloedverlies, heling wonde, emotioneel welzijn, ...)
              </li>
              <li class="collection-item transparent">
                Onderzoek van kind (bijvoorbeeld kleur, gewicht, voedingspatroon, heling navel, ...)
              </li>
              <li class="collection-item transparent">
                Begeleiding bij fles- en/of borstvoeding
              </li>
              <li class="collection-item transparent">
                Ondersteuning en informatie
              </li>
            </ul>
            <figure class="col s12 ">
              <img loading="lazy" mMaterialbox src="assets/wegen-min.webp" alt="Baby wegen" class="img-thumbnail" />
              <figcaption>Foto: Baby wegen</figcaption>
            </figure>
          </div>
        </div>
      <li>
        <div class="collapsible-header">
          <svg class="icon">
            <use xlink:href="assets/sprites.svg#diagnoses"></use>
          </svg>
          Workshops en consultaties
        </div>
        <div class="collapsible-body">
          <div class="row section workshops">
            <div class="col s12 m4">
              <a class="square card-panel white green-text hoverable" [routerLink]="['/workshop/babymassage']"
                href="/workshop/babymassage">
                <svg class="icon">
                  <use xlink:href="assets/sprites.svg#baby"></use>
                </svg>
                Babymassage
              </a>
            </div>
            <div class="col s12 m4">
              <a class="square card-panel white purple-text text-accent-2 hoverable"
                [routerLink]="['/workshop/draagconsult']" href="/workshop/draagconsult">
                <svg class="icon">
                  <use xlink:href="assets/sprites.svg#ribbon"></use>
                </svg>
                Draagconsult
              </a>
            </div>
            <div class="col s12 m4">
              <a class="square card-panel white blue-text text-accent-2 hoverable"
                [routerLink]="['/workshop/kolfconsult']" href="/workshop/kolfconsult">
                <svg class="icon">
                  <use xlink:href="assets/sprites.svg#breastpump"></use>
                </svg>
                Kolfconsult
              </a>
            </div>
            <div class="col s12 m4">
              <a class="square card-panel white pink-text text-accent-2 hoverable"
                [routerLink]="['/workshop/lactatieconsult']" href="/workshop/lactatieconsult">
                <svg class="icon">
                  <use xlink:href="assets/sprites.svg#breastfeeding"></use>
                </svg>
                Lactatieconsult
              </a>
            </div>
            <div class="col s12 m4">
              <a class="square card-panel white orange-text text-accent-2 hoverable"
                [routerLink]="['/workshop/zwangerschap']" href="/workshop/zwangerschap">
                <svg class="icon">
                  <use xlink:href="assets/sprites.svg#pregnant-woman"></use>
                </svg>
                Zwangerschapsworkshops
              </a>
            </div>
            <div class="col s12 m4">
              <a class="square card-panel white grey-text text-accent-2 hoverable" [routerLink]="['/afspraak']"
                href="/afspraak">
                <svg class="icon">
                  <use xlink:href="assets/sprites.svg#clock"></use>
                </svg>
                Opvolgingsconsultatie
              </a>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="collapsible-header">
          <svg class="icon">
            <use xlink:href="assets/sprites.svg#tools"></use>
          </svg>
          Productstalen en verkoop of verhuur materiaal
        </div>
        <div class="collapsible-body">
          <ul class="collection">
            <li class="collection-item transparent">
              Gratis leveranciersstalen (afhankelijk van wat er op dat moment te verkrijgen valt).
              Vb: Billendoekjes, zalfjes, baby-verzorging producten, ...
            </li>
            <li class="collection-item transparent">
              Kwalitatieve dubbele kolf <a [routerLink]="['/webshop/product/-MDstU3ZcY5IfLvPOkGA']"
                href="/webshop/product/-MDstU3ZcY5IfLvPOkGA">Chicture Plus</a>
            </li>
            <li class="collection-item transparent">
              Allerlei types draagsystemen: elastische- en gewevendoek, ring sling, mei tai, ...
            </li>
            <li class="collection-item transparent">
              <a
                href="https://www.babylonia.eu/sites/default/files/PDF/infobrochure_bb-sooze_nl-4_luik_digitaal.pdf">BB-sooze</a>,
              de kangoeroe draagdoek voor baby bij jou te laten slapen. Tip: te gebruiken na keizersnede
            </li>
            <li class="collection-item transparent">
              Neem zeker ook eens een kijkje in de <a [routerLink]="['/webshop']" href="/webshop">webshop</a>
            </li>
          </ul>
        </div>
      </li>

      <li>
        <div class="collapsible-header">
          <svg class="icon">
            <use xlink:href="assets/sprites.svg#exclamation-circle"></use>
          </svg>
          Wanneer het anders loopt
        </div>
        <div class="collapsible-body">
          <ul class="collection">
            <li class="collection-item transparent">
              Ondersteuning, toezicht en verzorging voor, tijdens en na een miskraam
            </li>
            <li class="collection-item transparent">
              Ondersteuning, toezicht en verzorging voor en na doodgeboorte, vroeggeboorte, …
            </li>
          </ul>
        </div>
      </li>

      <li>
        <div class="collapsible-header">
          <svg class="icon">
            <use xlink:href="assets/sprites.svg#wallet"></use>
          </svg>
          Tarieven
        </div>
        <div class="collapsible-body">
          <h6 class="gradient">Vroedkundige zorg</h6>
          <div class="mb-3">
            <p>Wij zijn gedeconventioneerd en werken via de derdebetalersregeling.</p>
            <p>
              Verzekerd bij een mutualiteit? Dan regelen wij een deel van de vergoeding van de vroedkundige zorg rechtstreeks met uw mutualiteit.
            </p>
            <ul class="browser-default">
              <li>
                Uw ziekenfonds betaalt tot 12 consultaties van een vroedvrouw terug tijdens de zwangerschap.
              </li>
              <li>
                Tijdens het eerste jaar na de bevalling heb je recht op <u mTooltip data-position="bottom"
                data-tooltip="(6 + 3 gemotiveerd door vroedvrouw)">9*</u> consultaties van de vroedvrouw. Bij complicaties kan je,
              mits je een medisch attest hebt, extra consultaties terugbetaald krijgen.
              </li>
            </ul>
            <p>
              Het gedeelte dat niet vergoed is door de mutualiteit (eigen bijdrage) wordt door de meeste hospitalisatieverzekeringen volledig gedekt. We raden
              aan om uw polis na te kijken van de hospitalisatieverzekering voor verdere details.
            </p>
            <p>
              De eigen bijdrage reken we af per consultatie. De details van de tarieven en eigen bijdragen kan u hier vinden.
              Voor de periode dat de hospitalisatieverzekering niet dekt hanteren wij een vaste lagere eigen bijdrage van
            </p>
            <ul class="browser-default">
              <li>
                € 25 tijdens de zwangerschap
              </li>
              <li>
                € 30 vanaf de 6de dag na de bevalling
              </li>
            </ul>
            <p>
              Bij een verhoogde tegemoetkoming/OMNIO statuut wordt de gehele zorg volledig via de mutualiteit gedekt,
              naar wettelijke bepalingen zijn de eigen bijdragen dan niet van toepassing.
            </p>
            <p>
              Indien u niet bent aangesloten bij de mutualiteit kan u het te betalen bedrag vinden in de kolom honorarium.
            </p>
            <figure>
              <img loading="lazy" mMaterialbox src="assets/tarieven2024.webp" alt="tarievenlijst 2024" class="img-thumbnail" />
              <figcaption><a href="assets/TarievenInfans2024.pdf" download>Download tarievenlijst 2024</a></figcaption>
            </figure>
          </div>

          <h6 class="gradient">Reiskosten voor huisbezoek</h6>
          <div class="mb-3">
            <table class="centered">
              <thead>
                <tr>
                  <th>Aantal km (enkele rit)</th>
                  <th>per huisbezoek</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>0-20km</td>
                  <td>Gratis</td>
                </tr>
                <tr>
                  <td>20-25km</td>
                  <td>€10,00</td>
                </tr>
                <tr>
                  <td>25-30km</td>
                  <td>€15,00</td>
                </tr>
                <tr>
                  <td>30-35km</td>
                  <td>€20,00</td>
                </tr>
                <tr>
                  <td>...</td>
                  <td><a href="#simulation">Bereken hier uw reiskost</a></td>
                </tr>
              </tbody>
            </table>
            
            <p>
              In sommige gevallen is er een tegemoetkoming van het ziekenfonds of andere verzekeraar mogelijk voor
              reiskosten:
            </p>
            <ul class="browser-default">
              <li>Bij prenatale zittingen van een risicozwangerschap (doktersattest vereist).</li>
              <li>Sommige hospitalisatieverzekeringen komen hierin tegemoet. Dit kan u navragen bij uw verzekeraar.
              </li>
            </ul>
          </div>

          <h6 class="gradient">Andere kosten</h6>
          <p class="mb-3">Wij rekenen geen andere kosten aan tot zover u binnen de enkele rijafstand van 20km van ons woont bij een huisbezoek.</p>

        </div>
      </li>
    </ul>
  </div>
</div>
