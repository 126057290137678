export const environment = {
  production: true,
  emulator: false,
  firebase: {
    apiKey: 'AIzaSyAXqLjO71v_l92AzJBlv0faJLsjh1eaCXg',
    authDomain: 'auth.infans.be',
    databaseURL: 'https://infans.europe-west1.firebasedatabase.app',
    projectId: 'infanscorp',
    storageBucket: 'infanscorp.appspot.com',
    messagingSenderId: '629220135401',
    appId: '1:629220135401:web:058e86713e7f2e8b'
  },
  stripe: {
    publishable_key: 'pk_live_CqRjMCtUOJqLDDYMcf2ajJmq00EZSKNvfW'
  },
  calendar: {
    i18n: {
      months: ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
      monthsShort: ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
      weekdays: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
      weekdaysShort: ['zon', 'maa', 'din', 'woe', 'don', 'vrij', 'zat'],
      weekdaysAbbrev: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za']
    }
  }
};
